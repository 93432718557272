import React from 'react'
import './Misson.css'
import vision from "../Assats/Scale-of-justice-lawyer-business-law.png"
import manif from "../Assats/blogs.jpg"
const Misson = () => {
  return (
    <div className="proccess-main">
    <div className="proccess">
      <div className="proccess-2">
      <h3>Our Vision </h3>
      <p>
      Coefficient nurtures a vision to groom into a firm that crafts best solutions that eases treading towards goals of business leaders.
The grooming is streaming towards one-stop hub of solutions for corporate governance.
The efforts are focused towards evolving as one of the most trusted and recommendable partner of vibrant business ideas.
      </p>
      </div>
      <div className="proccess-1">
      <img src={vision} alt=""/>
      </div>
    
    </div>

{/*- section2-*/}
<div className="proccess">
    
      <div className="proccess-1">
      <img src={manif} alt="" />
      </div>
      <div className="proccess-2">
        <h3>Our Mission</h3>
      <p>
      Change guised as transformation is inevitable friend of those who are marching towards their dream successes.
Coefficient is dedicated to play its part in crafting the idea and execution of business in the ever transforming environment.
We are witnessing the most radical transformative age and Coefficient is dedicatedly working for devising the adoptive measures as well as shaping the transformations as well.
      </p>
      </div>
    
    </div>

  </div>
  )
}

export default Misson
