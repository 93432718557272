import React from 'react'
import Sarvecess from '../Compnent/Sarvicess/Sarvecess'
import Footer from '../Compnent/Footer/Footer'

const Sarvice = () => {
  return (
    <div>
      <Sarvecess/>
      <Footer/>
    </div>
  )
}

export default Sarvice
