import React, { useEffect } from "react";
import Navbar from "./Compnent/Navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Page/Home";
import About from "./Page/About";
import Sarvice from "./Page/Sarvice";
import Blog from "./Page/Blog";
import Contect from "./Page/Contect";
import Audit from "./Page/Audit";
import Compliance from "./Page/Compliance";
import Advisory from "./Page/Advisory";
import Taxtion from "./Page/Taxtion";
import Articalsa from "./Compnent/Articals/Articalsa";
import Articalsb from "./Compnent/Articals/Articalsb";
import Articalsc from "./Compnent/Articals/Articalsc";
import Articalsd from "./Compnent/Articals/Articalsd";

import Aos from "aos";
import "aos/dist/aos.css";
const App = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Articalsd" element={<Articalsd />} />
          <Route path="/Articalsc" element={<Articalsc />} />
          <Route path="/Articalsb" element={<Articalsb />} />
          <Route path="/Articalsa" element={<Articalsa />} />
          <Route path="/About" element={<About />} />
          <Route path="/Sarvice" element={<Sarvice />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Contect" element={<Contect />} />
          <Route path="/Audit" element={<Audit />} />
          <Route path="/Compliance" element={<Compliance />} />
          <Route path="/Advisory" element={<Advisory />} />
          <Route path="/Taxtion" element={<Taxtion />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
