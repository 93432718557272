import React from 'react'
import './Articals.css'
import imagess from '../Assats/artacils2.jpg'


const Articalsd = () => {
  return (
    <div className='articilebg'>
    <img src={imagess} alt="" />
    <div className='artmain'>
<h1>Analysis of Section 161</h1>
<h2>Appointment of Additional Director, Alternate Director and Nominee Director</h2>
<b>(1) Additional Director:</b>
<p>
•	The article of a company may confer on its Board of Directors the power to appoint any person, as an additional director. <br />
•	Person who fails to get appointed as director in general meeting cannot be appointed as Additional Director of the company. <br />
•	Additional Director shall hold office up to the date of next AGM or the last date upto which AGM should have been hold, whichever is earlier. <br />
<b>Analysis</b> <br />
•	With a view to meet urgent requirements of management, the Board of Directors is empowered to appoint any person as an additional director at any time if such power is granted by articles. <br />
•	The person to be appointed as additional director must possess DIN and must not be the person who fails to get appointed as director in general meeting. <br />
•	Further an Additional Director is not a Retiring Director. Therefore his appointment as regular director requires that the provisions of section 160 are followed <br />

</p>
    </div>
    <div className='artmain1'>
<h2>Alternate Director</h2>
<p>
•  The board of director may, <br />
• if so authorised by or <br />
•  by a resolution passed by company in general meeting <br />
•   Appoint a person, not being a person holding any alternate directorship for any other director in the company or holding directorship in the same company, to act as alternate director for a director during his absence for a period of not less than 3 months from India. <br />
•	No person shall be appointed as alternate director for an independent director unless he is qualified to be appointed as an independent director. <br />
•<b>	Period of office –</b> Shall not hold office for a period longer than permissible to original director and shall vacate office if and when such original director returns to India. <br />
•	If the term of office of original director is determined before he returns to India, any provisions for automatic reappointment of retiring director shall apply to the original and not to the alternate director. <br />
<b>Analysis</b> <br />
•	The power to appoint an alternate director rest with BOD. However such appointment must be conferred by articles or by resolution passed at the general meeting. <br />
•	An alternate director is appointed in place of regular director who has gone out of 
India for minimum 3 Months or more. <br />
•	Such proposed alternate director should possess DIN. <br />
•	A person who is already an alternate director in the company cannot hold another alternate directorship in that same company. <br />
•	Further, Alternate director must not be a director in the same company.  <br />
•	For considering maximum no. of directorship of a person under section 165, the alternate directorship shall also be counted. <br />



</p>
    </div>
    <div className="artmain2">
        <h2>(3) Nominee Director</h2>
        <p>
        •	Subject to the article of the company, the Board may appoint any person as a Director nominated by any institution in pursuance of provisions of any law for the time being in force or of any agreement or by the CG or SG by virtue of its shareholding in a Government Company. <br />
<b>Analysis</b> <br />
•	A nominee director is not like any other Director. <br />
•	He represents the body which makes his nomination. <br />
•	Whenever a company obtains financial assistance from some financial institution or bank, such financial institution nominates his representative for safeguarding its interest till the loan amount is completely repaid. <br />

        </p>
    </div>
    <div className="artmain3">
        <h2>(4) Director appointed in Casual Vacancy</h2>
        <p>
        •	If the office of any Director appointed by company in general meeting is vacated before his term of office expires, the result casual vacancy may, in default of and such to articles by filled by BOD which shall be subsequently approved by members in immediate next general meeting. <br />
        •	Period of Office: shall hold office upto the date upto which the director in whose place he is appointed would have held office if it has not been vacated.


        </p>
    </div>
      
    </div>
  )
}

export default Articalsd
