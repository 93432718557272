import React from 'react'
import './Articals.css'
import imagess from '../Assats/articals3.jpg'

const Articalsc = () => {
  return (
    <div className='articilebg'>
        <img src={imagess} alt="" />
        <div className='artmain'>
<h1>Analysis of Section 162</h1>
<h2>Appointment of Directors to be voted individually </h2>
<p>
•	At a general meeting of a company, a motion for the appointment of two or more persons as Directors of the company by a single resolution shall not be moved unless a proposal to move such a motion has first been agreed to at the meeting without any vote being cast against it. <br />
•	A resolution moved in contravention of sub-section (1) shall be void, whether or not any objection was taken when it was moved. <br />
•	A motion for approving a person for appointment, or for nominating a person for appointment as a director, shall be treated as a motion for his appointment. <br />
•	Section 162 is not applicable for Private companies, Government companies and IFSC Public Company. <br />
<b>Analysis</b> <br />
•	This section provides that at a general meeting of the shareholders, a motion for appointment of two or more persons as director by a single resolution shall not be moved unless the proposal to move the motion in the above manner has been consented to by shareholders without any vote being cast against it. <br />
•	The logic for the provision is that such directors shall be appointed only by a separate resolution and not as group. <br />
•	This will enable the shareholders to apply his mind and to decide whether to vote in favour of the appointment or not. <br />
•	Bunching up of appointments makes it difficult for the shareholder to either accept or reject the entire lot. <br />
•	If the appointment of director is not voted individually, such appointment shall be void even if no shareholder has objected to the procedure. <b>(Raghunath Swaroop Mathur V Raghuraj Bahadur Mathur) <br />
                                                                                                                                         So, it can be concluded that to appoint directors by Single resolution we need to:</b>  <br />
I.	Pass a motion for the appointment of directors by a single resolution. <br />
II.	We need to make sure that each of the shareholders present in the general meeting cast vote in favor of the motion. <br />
III.	Even if 1 out of 7 members presents cast vote against the motion. The appointment by a single resolution will not be done. <br /> 
IV.	If a motion is moved, then the appointment of directors by a single resolution can be passed if it gets the majority votes. <br />








</p>
        </div>
        </div>
  )
}

export default Articalsc
