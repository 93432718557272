import React from 'react'
import Enqurary from '../Compnent/Enqurary/Enqurary'
import Footer from '../Compnent/Footer/Footer'
const Contect = () => {
  return (
    <div>
      <Enqurary/>
      <Footer/>
    </div>
  )
}

export default Contect
