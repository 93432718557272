import React from 'react'
import Aboust from '../Compnent/About/Abouts'
import Footer from '../Compnent/Footer/Footer'

const About = () => {
  return (
    <div>
      <Aboust/>
      <Footer/>
    </div>
  )
}

export default About
