import React from 'react'
import './Articals.css'
import imagess from '../Assats/articile.jpg'

const Articalsa = () => {
  return (
    <div className='articilebg'>
        <img src={imagess} alt="" />
        <div className='artmain'>
<h1>Analysis of Section 165</h1>
<h2>165(1)</h2>
<p>
•	No person shall be associated as Director in more than 20 companies at the same time. <br />
•	This section does not extend to him from being a manager of a company in more than 20 companies of which he is a director. <br />
•	Section 165(1) is not applicable to Section 8 Companies. <br />
<b>Proviso</b><br />
•	Maximum number of Directors in Public company shall not exceed 10. <br />

(i) Subsidiary of Public company is also a Public company as per section 2(71) so it will also be considered while computing the maximum no. of directors. <br />

(ii) For reckoning the limit of Directorships of twenty companies, the Directorship in a dormant company shall not be included. – Explanation II has been added by Companies (Amendment) act, 2017.

</p>
        </div>
        <div className='artmain1'>
<h2>165(2)</h2>
<p>
    
•	Members of a company may, by special resolution, specify any lesser number of companies in which a director of the company may act as Directors.br <br />

•	Shareholders of the company are empowered by Special Resolution to lesser number of companies in which a director of the company may act as Directors. <br />

•	They have the right to expect that its directors provide undivided time and attention to the progress of the company. <br />

•	Therefore they can place a restrain on the number of companies in which director of the company may act as Directors. <br />



</p>
        </div>
        <div className="artmain2">
            <h2>165(3)</h2>
            <p>•	Any person holding office as director in companies more than the limits as specified shall, within a period of one year take the following actions- <br />
(a) choose not more than the specified limit of those companies, as companies in which he wishes to continue to hold the office of director; <br />
(b) resign his office as director in the other remaining companies; and <br />
(c) intimate the choice made by him under clause (a), to each of the companies in which he was holding the office of director and to the Registrar having jurisdiction in respect of each such company. <br />


</p>
        </div>
        <div className="artmain3">
            <h2>165(4)</h2>
            <p>
            •	This subsection stipulates that where a director with a view to limit the number of directorship to the maximum, tendered his resignation in certain companies, the resignation so submitted shall take effect upon dispatch of such intimation to company concerned. <br />

<b>-	Contradiction between section 165(4) and section 168(1)- the later will prevail </b> <br />

•	Section 168(1) is a special provision dealing with resignation of directors. <br />
•	While section 165(4) is is nature of general provision. <br />
•	SC held that when special provision is made on a matter , then it is excluded from general provision. <br />
<b> Case law – Venkateshwar Raon V Govt of Madhya Pradesh</b> <br />

•	When a non executive director resigns his position, unless he species a prospective date of resignation to take effect, it is valid when he has decided to relinquish his position. 
<b> Case law – Glossop V Glossop</b>


            </p>
        </div>
      <div className="artmain4">
        <h2>165(5)</h2>
        <p>
            
•	No such person shall act as director in more than the specified number of companies,— <br />
(a) after despatching the resignation of his office as director or non-executive director thereof, in pursuance of clause (b) of sub-section (3); or <br />
(b) after the expiry of one year from the commencement of this Act, whichever is earlier. <br />

•	This subsection therefore presumes that a dorector who holds directorship beyond the limit should make his choice of directorships and restricts it to the maximum by sending his resignation within a reasonable period of time , failing which in any case he has to demit office after one year.
        </p>
      </div>
    </div>
  )
}

export default Articalsa
