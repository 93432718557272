import React from 'react'
import Hero from '../Compnent/Hero/Hero'
import Misson from '../Compnent/Misson/Misson'
import Stranth from '../Compnent/Stranth/Stranth'
import Why from '../Compnent/Why/Why'
import Footer from '../Compnent/Footer/Footer'
const Home = () => {
  return (
    <div>
      <Hero/>
      <Misson/>
      <Stranth/>
      <Why/>
      <Footer/>
    </div>
  )
}

export default Home
