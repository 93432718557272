import React from 'react'
import './Tex.css'
import texx from '../Assats/incometax.jpg'
import tex from '../Assats/texx.jpg'
const Tex = () => {
  return (
    <div className="text-main">
        <img src={texx} alt="" />
    <div className="text">
      <div className="text-2">
      <h3>TAXATION  </h3>
      <p>
      Income Tax Planning 
<br /><br />GST Structuring 
<br /><br />Representation before Tax Authorities
<br /><br />Filing and other Compliance

      </p>
      </div>
      <div className="text-1">
      <img src={tex} alt=""/>
      </div>
    
    </div>
      
    </div>
  )
}

export default Tex
