import React from 'react'
import './Blogs.css'
import blogc1 from '../Assats/audit1.jpg'
import blogc2 from '../Assats/audit2.jpg'
import blogc3 from '../Assats/audit3.jpg'
import blogc4 from "../Assats/audit4.jpg"
import blogcimg from "../Assats/blogs-banner.jpg"

import { Link } from 'react-router-dom'

const Blogs = () => {
  return (
    <div className="blogc-main">
    <img src={blogcimg} alt="" />
    <h1>ARTICLE</h1>
<div className="blogc-container">
    <div className="blogc">
        <img src={blogc1} alt="" />
        <div className="blogc-content">
            <h3>Analysis of Section 165</h3>
            <b>165(1)</b>
            <p>No person shall be associated as Director in more than 20 companies at the same time.
<br />This section does not extend to him from being a manager of a company in more than 20 companies of which he is a director.
<br />Section 165(1) is not applicable to Section 8 Companies.
</p>
            <button><Link to='/Articalsa'>Learn More</Link></button>
        </div>
    </div>
    <div className="blogc">
        <img src={blogc2} alt="" />
        <div className="blogc-content">
            <h3>Analysis of Section 163</h3>
            <p>	Notwithstanding anything contained in this Act, the articles of a company may provide for the appointment of not less than two-thirds of the total number of the Directors of a company in accordance with the principle of proportional representation, whether by the single transferable vote or by a system of cumulative voting or otherwise </p>
            <button><Link to='/Articalsb'>Learn More</Link></button>
        </div>
    </div>
    <div className="blogc">
        <img src={blogc3} alt=""/>
        <div className="blogc-content">
            <h3>Analysis of Section 162</h3>
            <p>	At a general meeting of a company, a motion for the appointment of two or more persons as Directors of the company by a single resolution shall not be moved unless a proposal to move such a motion has first been agreed to at the meeting without any vote being cast against it.</p>
            <button><Link to='/Articalsc'>Learn More</Link></button>
        </div>
    </div>
    <div className="blogc">
        <img src={blogc4} alt=""/>
        <div className="blogc-content">
            <h3>Analysis of Section 161</h3>
            <p> •	The article of a company may confer on its Board of Directors the power to appoint any person, as an additional director. <br />
            •	Person who fails to get appointed as director in general meeting cannot be appointed as Additional Director of the company.</p>
            <button><Link to='/Articalsd'>Learn More</Link></button>
        </div>
    </div>
</div>
</div>
  )
}

export default Blogs
