import React from 'react'
import './Hreo.css'

const Hero = () => {
  return (
    <div className='hero container'>
        <div className="hero-text">
            <h1> COEFFICIENT PARTNERS</h1>
            <p>Multiply value with our Expertise in Corporate Finance, Merger & Acquisition, Accounting,Tax, Registration & Compliance.
</p>
        </div>
      
    </div>
  )
}

export default Hero
