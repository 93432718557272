import React, {useState} from 'react'
import './Navbar.css'
import manu from "../Assats/menu-icon.png"

import { Link } from 'react-router-dom'
const Navbar = () => {
  const [mobileMenu,setMobileMenu]=useState(false);
  const toggleMenu =()=>{
    mobileMenu ? setMobileMenu(false) :setMobileMenu (true);
  }
  return (
    <nav className='container'>
      <p>COEFFICIENT <br /> PARTNERS
      </p>
     
      <ul className={mobileMenu?'':'hide-mobile-menu'}>
      <li >  <Link  to='/'>Home</Link></li> 
      <li >  <Link  to='/About'>About</Link></li>
        <li><Link to='/Sarvice'>Service</Link>
        <ul>
          <li><Link to='/Audit'> AUDITING</Link></li>
          <li><Link to='/Compliance'> REGISTRATIONS</Link> </li>
          <li><Link to='/Advisory'> ADVISORY</Link></li>
          <li><Link to='/Taxtion'> TAXATION</Link></li>
        </ul>
         </li>
        <li >  <Link  to='/Blog'>Blog</Link></li>
        <li >  <Link  to='/Contect'>Contact</Link></li> 
      </ul>
      <img src={manu} alt="" className='menu' onClick={toggleMenu} />
    </nav>
  )
}

export default Navbar
