import React from 'react'
import './Articals.css'
import imagess from '../Assats/artacils2.jpg'

const Articalsb = () => {
  return (
    <div className='articilebg'>
        <img src={imagess} alt="" />
        <div className='artmain'>
<h1>Analysis of Section 163</h1>
<h2>Option to Adopt Principle of Proportional Representation for Appointment of Directors </h2>
<p>
•	Notwithstanding anything contained in this Act, the articles of a company may provide for the appointment of not less than two-thirds of the total number of the Directors of a company in accordance with the principle of proportional representation, whether by the single transferable vote or by a system of cumulative voting or otherwise  <br />

•	Such appointments may be made once in every three years <br />
•	Casual vacancies of such Directors shall be filled as provided in sub-section (4) of section 161. <br />
•	This section is not applicable on Government Companies. <br />


</p>
        </div>
        <div className='artmain1'>
<h2>Analysis</h2>
<p>
    
•	This section overrides all other provisions of the act. <br />
•	It stipulates that company’s article may provide the appointment of not less than two-third of total number of directors shall be elected as per principle of proportional representation. <br />
•	Such appointment shall be made once in three year. <br />
•	Appointment of directors in casual vacancy shall continue to be done through section 161(4). <br />
•	The concept of Proportional Representation facilitates the possibility of each group of shareholders having their representation in the board in proportion to their voting power in the total share capital of the company. <br />
•	This system ensures that at least two third of directors are appointed through this process. <br />
•	Application of this section is only restricted to appointment of directors. <br />
•	This section ensures that minority shareholders shall have representation on the board. <br />



</p>
        </div>
        </div>
  )
}

export default Articalsb
