import React from 'react'
import './Regrastation.css'
import regs from '../Assats/audit3.jpg'
import ragst from '../Assats/regstration.jpg'
import reest from '../Assats/audit3.jpg'

const Regrastation = () => {
  return (
    <div className="regs-main">
        <img src={ragst} alt="" />
    <div className="regs">
      <div className="regs-2">
      <h3>Corporate Compliance  </h3>
      <p>
      Coefficient has a separate wing of experts to deal with all sorts of regulatory compliances and registrations. Coefficient ensures hassle-free and timely fulfilment of required compliances of its clientele that compliments their growth, quality and efficiency. Our Compliance following Laws:- <br />
      1.	Company Law
<br />2.	Securities Law
<br />3.	Taxation 
<br />4.	Labour Laws
<br />5.	Other Corporate laws 

      </p>
      </div>
      <div className="regs-1">
      <img src={regs} alt=""/>
      </div>
    
    </div>
    {/*- section2-*/}
    <div className="regs">
    
      <div className="regs-1">
      <img src={reest} alt="" />
      </div>
      <div className="regs-2">
        <h3>Merger and Acquisition </h3>
      <p>
      Merger and acquisition is trending as a booster for business enterprises. It is indeed an advantageous idea that demands utmost precision while exercising the whole procedure. Coefficient has experience in handling it meticulously with extensive knowledge. Each step is executed with precision throughout the lifecycle to achieve the desired result. The track-record acknowledges our efforts. 
      <br />
      <br />
      <br />
      <b>Restructuring </b>
      <br />Company Setup
<br />Statutory Registrations for running the business
<br />Industry Specific Registration
<br />Registrations under Labour Law

      </p>
      </div>
    
    </div>
    </div>
  )
}

export default Regrastation
