import React from 'react'
import './Advisor.css'
import advises from "../Assats/advisor.jpg"
import avisor from "../Assats/regs.jpg"

const Asvisor = () => {
  return (
    <div className="advis-main">
        <img src={avisor} alt="" />
   
    {/*- section2-*/}
    <div className="advis">
    
      <div className="advis-1">
      <img src={advises} alt="" />
      </div>
      <div className="advis-2">
        <h3>TRANSACTION ADVISORY </h3>
      <p>
      
    <b>  i.</b>	Planning and Execution of Various investment scheme in Companies in the form of Startup funding, Private Placement, ESOP, Sweat Equity, FDI and issuance of Various kind of notes.
<br /><br />  <b>  ii.	Corporate Structuring :- </b>		<br />Takeover 
	<br />Merger and Amalgamation under Company Law
<br />Business Transfers
	<br />Company Closure
<br /><br /> <b> iii.	Due Diligence :- </b>	<br />		Conduct due diligence <br />
Represent Target Companies in Due Diligence <br /><br />
<br /><b> iv.	Transaction Documentations : - </b>	<br />	Drafting of Agreements, Deeds, Schemes and other 
requisite documents <br />
Financial Modeling and Valuation for Transaction
<br /><br /><b> v.	Transaction Related Compliance :-</b> <br />	Reporting with Registrar of Companies <br />
Reporting with Reserve Bank of India <br />
Reporting with DIPP  <br />
Reporting with Tax Authorities <br />
Reporting with Departments 



 


      </p>
      </div>
    
    </div>
    </div>
  )
}

export default Asvisor
