import React from 'react'
import './Why.css'
const Why = () => {
  return (
    <div className="landing">
    <div className="landing-1">
    <b>Why Coefficient</b>
    <p>1.	Coefficient is a perfect listener and profound enthusiast. 
<br />2.	Coefficient‘s approach blends basic, innovation, pragmatism and boldness.  
<br />3.	Team Coefficient is its asset and is regularly stretching its canvas of successes.
<br />4.	The anticipation of risk and prescription for resolution at Coefficient has a phenomenal track record testifying the quantum of dedication.  
<br />5.	Coefficient continuously hones skills in-house. 

</p>
    </div>
   
  
  </div>
  )
}

export default Why
