import React from 'react'
import Regrastation from '../Compnent/Regrastration/Regrastation'
import Footer from '../Compnent/Footer/Footer'

const Compliance = () => {
  return (
    <div>
      <Regrastation/>
      <Footer/>
    </div>
  )
}

export default Compliance
