import React from 'react'
import Blogs from '../Compnent/Blogs/Blogs'

const Blog = () => {
  return (
    <div>
     <Blogs/>
    </div>
  )
}

export default Blog
