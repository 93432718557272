import React from 'react'
import './Footer.css'
import whatshap from '../Assats/whatsapp_4423697.png'
import facebook from '../Assats/message.png'
import instra from '../Assats/instagram_174855.png'
import twiter from '../Assats/twitter2.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer-main'>
    <div className="footer">

<div className="col">
<h3>Office</h3>
 Office: C-56/5,<br /> 2nd Floor, Mantec Tower, Noida, Sector-62,<br /> UP-201309
<br />
+91-9999052033
</div>
<div className="col">
<h3>Link</h3>
<ul>
    <li><Link  to='/'>Home</Link></li>
    <li >  <Link  to='/About'>About</Link></li>
    <li><Link to='/Sarvice'>Service</Link> </li>
    <li >  <Link  to='/Contect'>Contact</Link></li>  
    
</ul>
</div>
<div className="col">
<h3>Social Media</h3>
<div className='media'>
<a href=" https://wa.me/919999052033?=Hello How can i help you ?"> <img src={whatshap} alt=""  /></a>
<a href="https://in.linkedin.com/company/coefficient-advisors"><img src={facebook} alt="" /></a>
<a href="https://x.com/account/access"><img src={twiter} alt="" /></a>
<img src={instra} alt="" />
</div>
</div>
</div>
<hr />
<div className="copi"><p>Coefficient Partners © 2016-All Right Receved </p></div>

</div>
  )
}

export default Footer
