import React from 'react'
import './About.css'
import aboutus from "../Assats/main1.jpg"

const Aboust = () => {
  return (
    <div className='about-bg'>
    <img src={aboutus} alt=""  />
   {/* <h1>ABOUT US</h1>*/}
   <div className="about1">
   <div className="about">
         <b>ABOUT US</b>
         
         <p>
         Coefficient Partners is a corporate consulting firm that collaborates to craft corporate wisdom. Business enterprise requires strategy, execution and adoption for sustainable growth. Our efforts are directed towards providing our clients the best counsel to ensure impeccable decisions and perfect execution translating into a sustainable success. Coefficient tenders consultancy with comprehensive understanding and knowledge in matters related to corporate finance, merger and acquisition, auditing, taxation, accounting, registration, compliance and legal issues. Its approach mingles basics and boldness along with innovations and pragmatism. The idea of advisory is simply to provide the nutrients needed for the precious ideas of business leaders. Coefficient clientele ranges from galloping enterprises to market leaders.
</p>
         </div>  
   </div>
   </div>
  )
}

export default Aboust
