import React from 'react'
import './Sarvecess.css'
import card1 from '../Assats/audit1.jpg'
import card2 from '../Assats/audit2.jpg'
import card3 from '../Assats/audit3.jpg'
import card4 from "../Assats/audit4.jpg"
import cardimg from "../Assats/sarvice1.jpg"

import { Link } from 'react-router-dom'

const Sarvecess = () => {
  return (
    <div className="card-main">
        <img src={cardimg} alt="" />
        <h1>Service</h1>
<div className="card-container">
        <div className="card">
            <img src={card1} alt="" />
            <div className="card-content">
                <h3>AUDITING</h3>
                <p>Providing assurance services under various statutes across various industries for the past several decades.</p>
                <button><Link to='/Audit'>Learn More</Link></button>
            </div>
        </div>
        <div className="card">
            <img src={card2} alt="" />
            <div className="card-content">
                <h3>COMPLIANCE AND REGISTRATIONS</h3>
                <p>CareSource may require documentation of FDA-approved complexity level for instrumented equipment, and/or CLIA Certificate of Registration, Compliance, or Accreditation as a high complexity lab.</p>
                <button><Link to='/Compliance'>Learn More</Link></button>
            </div>
        </div>
        <div className="card">
            <img src={card3} alt=""/>
            <div className="card-content">
                <h3>TRANSACTION ADVISORY</h3>
                <p>provides financial expertise and strategic guidance to clients involved in mergers, acquisitions, or divestitures.</p>
                <button><Link to='/Advisory'>Learn More</Link></button>
            </div>
        </div>
        <div className="card">
            <img src={card4} alt=""/>
            <div className="card-content">
                <h3>TAXATION</h3>
                <p> a term for when a taxing authority, usually a government, levies or imposes a financial obligation on its citizens or residents</p>
                <button><Link to='/Taxtion'>Learn More</Link></button>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Sarvecess
