import React from 'react'
import Audits from '../Compnent/Audit/Audits'
import Footer from '../Compnent/Footer/Footer'

const Audit = () => {
  return (
    <div>
      <Audits/>
      <Footer/>
    </div>
  )
}

export default Audit
