import React from 'react'
import Tex from '../Compnent/Tex/Tex'
import Footer from '../Compnent/Footer/Footer'

const Taxtion = () => {
  return (
    <div>
      <Tex/>
      <Footer/>
    </div>
  )
}

export default Taxtion
