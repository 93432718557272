import React from 'react'
import './Stranth.css'
const Stranth = () => {
  return (
    <div className='banner'  data-aos="fade-up"
    data-aos-duration="3000">
      
      <div className="imgtext">
        <h2>Our Strength</h2>
        <p>Team Coefficient is an asset that is constituted by professionals having knowledge-driven expertise and result-driven experience.  This cohesive team has drawn drivers of various fields and mutually developed magnetic work culture that results into best efforts for clients.  </p>
      </div>
    </div>
  )
}

export default Stranth
