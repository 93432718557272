import React from 'react'
import Asvisor from '../Compnent/Advisor/Asvisor'
import Footer from '../Compnent/Footer/Footer'

const Advisory = () => {
  return (
    <div>
      <Asvisor/>
      <Footer/>
    </div>
  )
}

export default Advisory
